<template>
        <div class="container-menu-left-flex">
                <h1>Menu</h1>
                <a v-scroll-to="{ element: '#intro', duration: 1600 }" href="#intro" class="btn-contact">Apresentação</a>
                <a v-scroll-to="{ element: '#temas', duration: 1600 }" href="#temas" class="btn-contact">Sobre</a>
                <a v-scroll-to="{ element: '#install', duration: 1600 }" href="#install" class="btn-contact">Instalação</a>
                <a v-scroll-to="{ element: '#imagens', duration: 1600 }" href="#imagens" class="btn-contact">Imagens Tamanho</a>
                <a v-scroll-to="{ element: '#cores', duration: 1600 }" href="#cores" class="btn-contact">Cores</a>
                <a v-scroll-to="{ element: '#banners', duration: 1600 }" href="#banners" class="btn-contact">Banners</a>
                <a v-scroll-to="{ element: '#funcoes', duration: 1600 }" href="#funcoes" class="btn-contact">Funções</a>
                <a v-scroll-to="{ element: '#vitrine', duration: 1600 }" href="#vitrine" class="btn-contact">Vitrines</a>
                <a v-scroll-to="{ element: '#redes', duration: 1600 }" href="#redes" class="btn-contact">Redes Sociais</a>
                <a v-scroll-to="{ element: '#iconografia', duration: 1600 }" href="#iconografia" class="btn-contact">Iconografia</a>
                <a v-scroll-to="{ element: '#editarhtml', duration: 1600 }" href="#editarhtml" class="btn-contact">Editar Html</a>
                <a v-scroll-to="{ element: '#imgpadrao', duration: 1600 }" href="#imgpadrao" class="btn-contact">Editar Imagens Padrões</a>
               

        </div>
</template>


<script>

export default {
 data(){
        return{    
        }
    }
};

</script>

<style scoped>
.container-menu-left-flex{
        display: flex;
        flex-direction: column;
        width: 280px;
        background-color: #f5f5f5;
        position: fixed;

}
.container-menu-left-flex h1{
      font-size: 30px;
      color: #b03434;
      text-decoration: none;
      padding: 10px 20px;
     
        
}
.container-menu-left-flex a{
      font-size: 20px;
      color: #020202cf;
      text-decoration: none;
      padding: 12px 20px;     
}


@media (max-width: 1024px) {
  .container-menu-left-flex{
          display: none;
  }
}


</style>
