<template>
<div class="body-docu">

<h1>Documentação Temas Mamd v2 Pro</h1>

<div class="container-body-docu-flex">
 
     <div id="intro" class="">
       <h2 v-on:click="isHidden = !isHidden" >Apresentação <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>
       <p v-if='isHidden'>Este tema tem como base as boas práticas recomendadas pelo Google para um melhor desempenho na indexação de sua loja virtual. A agência Mamd tem como referência de layout e funções os grandes marketplaces do mercado brasileiro, sempre tentando trazer novidades para o lojista em questões de conversão. O nosso básico de funcionalidade é o completo da concorrência, nosso preço é acessível para qualquer lojista independente do segmento da sua loja.</p>
    </div>

     <div id="temas" class="">
       <h2 v-on:click="isHidden1 = !isHidden1" >Sobre <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>
       <p v-if='isHidden1'>A finalidade deste manual é direcionar o cliente na configuração de sua loja com agilidade e sem precisar depender de outros colaboradores ou parceiros, essa documentação corresponde ao temas:</p>
        <h3 v-if='isHidden1'>Cart Parts </h3>
        <h3 v-if='isHidden1'>Moda </h3>
        <h3 v-if='isHidden1'>Mens </h3>

    </div>


     <div id="install" class="">
       <h2 v-on:click="isHidden2 = !isHidden2" >Instalação <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>
       <p v-if='isHidden2'>Depois de efetivar a compra do tema, você deve ir em minha loja > designer da loja, para sua segurança duplicar o tema, deixando como um backup e assim podendo modificar a sua melhor maneira sem se preocupar em perda do código. É importante também não mexer no html sem ter um conhecimento avançado em programação.
Seu tema por padrão não fica em produção, ou seja ninguém além de você vai visualizar o conteúdo da loja, a não ser que tenha a url com todos os parâmetros do tema que é bem difícil acontecer.
Para publicar seu tema é bem simples basta entrar em minha loja > designer da loja ir no seu tema que já está todo configurado, e dar um click em “Publicar” assim sua loja vai estar à disposição de todo público web.
</p>

    <img v-if='isHidden2' src="@/assets/documentacaov2/tema-botoes.png">

    </div>


	 <div id="imagens" class="">
       <h2 v-on:click="isHidden17 = !isHidden17" >Imagens Tamanho<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>
          <div v-if='isHidden17'>

		 <h3>Imagens de Produtos</h3>  
		   <p>500x500 Formato JPG</p>
		
		 <h3>Imagens de Logo</h3>  
		   <p>500x500 Formato SVG ou 250x100 JPG</p>


		 <h3>Imagens de Banner Carrossel</h3>  
		   <p>1900x600 Formato JPG</p>

		 <h3>Imagens de Banner Carrossel mobile</h3>  
		   <p>640x640 Formato JPG</p>  

		<h3>Imagens de Banner Full</h3>  
		   <p>1900 largura e na altura fica a gosto Formato JPG</p>    

		<h3>Imagens de Banner Double</h3>  
		   <p>512 largura e na altura fica a gosto Formato JPG</p>          
		
		<h3>Imagens de Banner Triple</h3>  
		   <p>300 largura e na altura fica a gosto Formato JPG</p>  

	

		<h3>Imagens de Banner Carrossel de Marcas</h3>  
		   <p>300x300 Formato JPG</p>    

		
	      


		</div>
    </div>

      <div id="cores" class="">
       <h2 v-on:click="isHidden3 = !isHidden3" >Cores <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>
       <p v-if='isHidden3'>Na aba de cores você pode escolher sua cor de preferência no quadrado menor ou escrever dentro do campo branco, seguindo todas as ilustrações correspondendo para cada item a ser modificado, é extremamente simples de trocar as cores do seu tema.</p>

    <img v-if='isHidden3' src="@/assets/documentacaov2/corestemas.png">

    </div>



      <div id="banners" class="">
       <h2 v-on:click="isHidden4 = !isHidden4" >Banners <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>
       <h3 v-if='isHidden4' v-on:click="sbuTitle = !sbuTitle">Banner Carrossel <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
       <p v-if='sbuTitle'>Esse banner é o principal banner da loja ele fica localizado no topo da loja, nossa estratégia é utilizar 3 banners de 1900 x 600 para o desktop e 3 banner mobile de 500 x 500. Você pode escolher o estilo de carrossel, o tempo de intervalo de um banner para o outro e as cores das setas. Para cadastrar os banners o usuário tem que entrar no painel da tray, ir em Marketing > Banners da loja e depois ir em cadastrar banner.</p>
       <p v-if='sbuTitle'>No cadastro do banner de carrossel você deve escolher “sim” em “banner rotativo para várias promoções“, inserir pelo menos 2 banners desktop de 1900 x 600, selecionar o local de onde o banner irá aparecer que é “banner Home“, depois inserir o tempo desse exibição do banner, o resto apenas ignore porque todas a configuração já vai ser feita dentro do editor do tema e salvar no botão que fica em baixo.</p>
        <img v-if='sbuTitle' src="@/assets/documentacaov2/editartemabannerslide.png">
       <p v-if='sbuTitle'>Para o banner mobile do carrossel é preciso ir em cadastrar banner novamente e cadastrar os banners mobile de 500 x 500 em “Banner extra 10”, “Banner extra 11” e “Banner extra 12” esses banners extras estão reservado para o mobile seguindo pela sua respectiva ordem de exibição na loja.</p>
       
        <h3 v-if='isHidden4' v-on:click="sbuTitle1 = !sbuTitle1">Banner Régua  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
       <p v-if='sbuTitle1'>O banner régua você pode habilitar ele para aparecer, escolher o texto de cada um dos cinco elementos, editar as cores dos textos e ícones e escolher a posição de onde ele vai aparecer na página home da loja.</p>
        <img v-if='sbuTitle1' src="@/assets/documentacaov2/bannerregua.png">


        <h3 v-if='isHidden4' v-on:click="sbuTitle2 = !sbuTitle2">Banner de Categorias <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
        <p v-if='sbuTitle2'>No banner de categorias tem a possibilidade de habilitar ele para ser exibido na página home, escolhendo o nível de categorias e o título. Esse banner de categorias funciona se for cadastrado todas as imagens das categorias, nessa mesma documentação em Iconografia tem todos os passos para cadastrar imagens nas categorias.</p>
        <img v-if='sbuTitle2' src="@/assets/documentacaov2/bannercategorias.png">


        <h3 v-if='isHidden4' v-on:click="sbuTitle3 = !sbuTitle3">Banner Extra <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
        <p v-if='sbuTitle3'>A possibilidade de habilitar 3 banner extras e cada um pode ter até 3 mini banners, o banner pode ser escolhido como full 1900 de largura, duplo 512 de largura ou triplo em 312 de largura, fica a critério do cliente definir o tamanho de cada banner.</p>
        <img v-if='sbuTitle3' src="@/assets/documentacaov2/bannerextras.png">


        <h3 v-if='isHidden4' v-on:click="sbuTitle4 = !sbuTitle4">Carrossel de Marcas <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
        <p v-if='sbuTitle4'>O carrossel de marcas pode ser habilitado para ser exibido na home da página, ele é um carrossel infinito de imagens. Para cadastrar cada imagens do carrossel basta ir em marketing > banners da loja e clicar em cadastrar banner. No cadastro do banner de carrossel você deve escolher “sim” em “banner rotativo para várias promoções“, inserir pelo menos 2 banners de 300 x 300, o máximo é de 6 banners, selecionar o local de onde o banner irá aparecer que é “Banner Vitrine“, depois inserir o tempo desse exibição do banner, o resto apenas ignore porque todas a configuração já vai ser feita dentro do editor do tema e salvar no botão que fica em baixo.</p>
        <img v-if='sbuTitle4' src="@/assets/documentacaov2/carrosselmarcas.png">

    </div>


  <div id="funcoes" class="">
       <h2 v-on:click="isHidden5 = !isHidden5" >Funções <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>

    <h3 v-if='isHidden5' v-on:click="sbuTitle5 = !sbuTitle5">Depoimentos <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle5' >Habilitar os depoimentos que serão exibidos na home da loja e escolher as cores que estão indicadas por números na imagem de ilustração.</p>
    <img v-if='sbuTitle5' src="@/assets/documentacaov2/depoimentos.png">


 <h3 v-if='isHidden5' v-on:click="sbuTitle6 = !sbuTitle6"> Notícias <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle6'>Habilitar as notícias que serão exibidas na home da loja e escolher as cores que estão indicadas por números na imagem de ilustração. </p>
    <img v-if='sbuTitle6' src="@/assets/documentacaov2/noticias.png">


 <h3 v-if='isHidden5' v-on:click="sbuTitle7 = !sbuTitle7"> Pop up de saída <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle7'>Habilitar o pop up que será exibido na loja, inserir o título e escolher as cores que estão indicadas por números na imagem de ilustração.</p>
    <img v-if='sbuTitle7' src="@/assets/documentacaov2/popup.png">

 
 

 <h3 v-if='isHidden5' v-on:click="sbuTitle9 = !sbuTitle9"> LGPD pop up  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle9'>Habilitar as LGPD que serão exibidas na loja, inserir a url para redirecionar o cliente sobre os termos usados e escolher as cores que estão indicadas por números na imagem de ilustração.</p>
    <img v-if='sbuTitle9' src="@/assets/documentacaov2/lgpd.png">




 <h3 v-if='isHidden5' v-on:click="sbuTitle8 = !sbuTitle8">Selo google safe<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle8'>Habilitar o selo do Google Safe que será exibido no rodapé de toda loja.</p>
    <img v-if='sbuTitle8' src="@/assets/documentacaov2/googlesafe.png">






 <h3 v-if='isHidden5' v-on:click="sbuTitle10 = !sbuTitle10">Cronômetro   <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle10'>Habilitar cronômetro da loja exibida na home da loja e escolher as cores que estão indicadas por números na imagem de ilustração. Para funcionar o cronômetro é necessário primeiro que o produto cadastrado que queria ser exibido com o cronômetro, tenha uma promoção com data de início e final, isso tudo é feito dentro do “Produtos Cadastrados”.</p>
    <img v-if='sbuTitle10' src="@/assets/documentacaov2/cronometro.png">




 <h3 v-if='isHidden5' v-on:click="sbuTitle11 = !sbuTitle11">Avaliação   <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle11'>Habilitar as avaliações que serão exibidas em toda loja e escolher as cores que estão indicadas por números na imagem de ilustração.</p>
    <img v-if='sbuTitle11' src="@/assets/documentacaov2/avaliacao.png">




 <h3 v-if='isHidden5' v-on:click="sbuTitle13 = !sbuTitle13">Produtos 3 imagens na home<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle13'>Habilitar a exibição de 3 imagens extra em cada produto na home da loja para que o cliente tenha uma visão melhor dos produtos sem precisar entrar na página de produtos.</p>
    <img v-if='sbuTitle13' src="@/assets/documentacaov2/produto3.png">




 <h3 v-if='isHidden5' v-on:click="sbuTitle12 = !sbuTitle12"> Pontos de Fidelidade  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle12'>Habilitar pontos de fidelidade que será exibida na página de produtos devidamente cadastrados em marketing > programa de fidelidades.</p>
    <img v-if='sbuTitle12' src="@/assets/documentacaov2/pontos.png">




 <h3 v-if='isHidden5' v-on:click="sbuTitle14 = !sbuTitle14">Altura largura peso e comprimento
   <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle14'>Habilitar altura, largura, peso e comprimento na página de produtos.
</p>
    <img v-if='sbuTitle14' src="@/assets/documentacaov2/altura.png">




 <h3 v-if='isHidden5' v-on:click="sbuTitle15 = !sbuTitle15">Busca com Marca e Modelos   <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle15'>Habilitar a busca com marcas e modelos que serão exibidas na home da loja, inserir os botões e escolher as cores que estão indicadas por números na imagem de ilustração. Para funcionar é necessário cadastrar todas as marcas e modelos dentro de “Produtos cadastrados" esse filtro não funciona para produtos universal.</p>
    <img v-if='sbuTitle15' src="@/assets/documentacaov2/buscamarcas.png">



 <h3 v-if='isHidden5' v-on:click="sbuTitle16 = !sbuTitle16">Newsletter no meio da home   <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg></h3>
    <p v-if='sbuTitle16'>Habilitar newsletter no meio da página home.</p>
    <img v-if='sbuTitle16' src="@/assets/documentacaov2/newsletterhome.png">



    </div>




  <div id="vitrine" class="">
       <h2 v-on:click="isHidden6 = !isHidden6" >Vitrines <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>
       <p v-if='isHidden6'>Na aba vitrine você pode editar até 3 vitrines na página home da loja, escolher o tipo de vitrine, carrossel ou listagem, quantidade de produtos para ser mostrado, filtragem de produtos, cores e título de cada vitrine. O filtro de produtos não pode ser usado com o mesmo valor em outra vitrine.</p>
    <img v-if='isHidden6' src="@/assets/documentacaov2/vitrinestemas.png">

    </div>




  <div id="redes" class="">
       <h2 v-on:click="isHidden7 = !isHidden7" >Redes Sociais <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>
       <p v-if='isHidden7'>Na aba redes sociais você deve inserir a url completa de suas redes sociais e trocar a cor do ícone se quiser.</p>

    <img v-if='isHidden7' src="@/assets/documentacaov2/redesocial.png">

    </div>



  <div id="iconografia" class="">
       <h2 v-on:click="isHidden8 = !isHidden8" >Iconografia <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>
       <p v-if='isHidden8'>Para ter todos os ícones ou imagens nas categorias é preciso primeiro cadastrar o aplicativo grátis “imagens na categoria e subcategoria” na sua loja.
Primeiro você deve ir em “Meus aplicativos”, depois dar um click em instalar novo aplicativo, assim vão aparecer vários aplicativos gratuitos e pagos, para facilitar a busca coloque no primeiro filtro de categorias “Layout” e depois é só clicar no botão Filtrar. Irá aparecer vários outros apps de layout o que estamos buscando é um com o nome “imagens na categoria e subcategoria” que tem capacete laranja, nisso é apenas instalar e começar a cadastrar suas imagens nas devidas categorias.
       </p>

    <img v-if='isHidden8' src="@/assets/documentacaov2/appiconecategorias.png">

    </div>



  <div id="editarhtml" class="">
       <h2 v-on:click="isHidden9 = !isHidden9" >Editar Html <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>
       <p v-if='isHidden9'>Esse botão tem como funcionalidade entrar dentro das pastas de códigos do tema, é extremamente importante deixar claro que ninguém sem conhecimento pode editar o código do tema, caso isso aconteça o dono do respectivo tema perde o suporte da agência. Toda modificação fica registrada no histórico de edição, não tem como tentar ludibriar a agência ou a Tray. Se o comprador do tema quiser modificar o tema, ele tem que ficar ciente que qualquer bug a agência não vai se responsabilizar e nem tem como dar mais suporte.</p>

    </div>



  <div id="imgpadrao" class="">
       <h2 v-on:click="isHidden10 = !isHidden10" >Editar Imagens Padrões<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="94.859px" height="94.858px" viewBox="0 0 94.859 94.858" style="enable-background:new 0 0 94.859 94.858;" xml:space="preserve">
<g><g><path d="M17.312,41.757h60.236c0.77,0,1.469-0.44,1.802-1.133c0.334-0.691,0.241-1.514-0.237-2.114L48.994,0.753
			C48.614,0.277,48.039,0,47.431,0s-1.184,0.277-1.563,0.753L15.748,38.51c-0.479,0.601-0.572,1.423-0.238,2.114
			C15.843,41.317,16.542,41.757,17.312,41.757z"/>
		<path d="M77.547,53.102H17.312c-0.769,0-1.469,0.439-1.802,1.133c-0.334,0.691-0.241,1.514,0.238,2.113l30.119,37.758
			c0.38,0.476,0.955,0.753,1.563,0.753s1.184-0.277,1.563-0.753L79.11,56.348c0.479-0.601,0.571-1.422,0.237-2.113
			C79.016,53.541,78.317,53.102,77.547,53.102z"/>
	</g></g></svg>
</h2>
       
    <p v-if='isHidden10'>Esse botão só aparece no tema publicado, ou seja qualquer imagens inserida vai aparecer em todos os outros temas por padrão, algumas agencia nao trabalham com esse tipo de imagens porém é recomendado usar as imagens sempre dentro no painel da Tray, nunca dentro do editor de temas, isso faz com que suas imagens fiquem protegidas pela cdn da Tray.</p>
    <img v-if='isHidden10' src="@/assets/documentacaov2/editarimgs.png">
     
     <h3 v-if='isHidden10'>Logo </h3>
    <p v-if='isHidden10'>Sempre que possível utilizar imagens no formato svg do tamanho 500x500, caso contrário inserir a sua logo no tamanho 250x100 em png ou jpg..</p>


 <h3 v-if='isHidden10'>Favicon </h3>
    <p v-if='isHidden10'>O favicon é cadastrado no tamanho que a tray recomenda.</p>

 <h3 v-if='isHidden10'>Botões </h3>
    <p v-if='isHidden10'>No caso de imagens para botões não recomendados pelo SEO é sempre melhor ter um botão no código do que com uma imagem fixa.</p>
    <img v-if='isHidden10' src="@/assets/documentacaov2/logofavicontemas.png">


    </div>




</div>
</div>

</template>


<script>

export default {

 data(){
        return{ 
           isHidden: false,  isHidden1: false,  isHidden2: false,  isHidden3: false,  isHidden4: false,  isHidden5: false,  isHidden6: false,  isHidden7: false,isHidden8: false,isHidden9: false,isHidden10: false,
           sbuTitle: false,  sbuTitle1: false, sbuTitle2: false, sbuTitle3: false, sbuTitle4: false, sbuTitle5: false, sbuTitle6: false, sbuTitle7: false, sbuTitle8: false, sbuTitle9 : false, sbuTitle10: false,
		   sbuTitle11: false, sbuTitle12: false, sbuTitle13: false, sbuTitle14: false,sbuTitle15: false, sbuTitle16: false,
		   isHidden17: false, sbuTitle17: false,
        }
    }
};
</script>

<style scoped>
.body-docu{
        width: 100%;
    margin-left: 285px;
}
.body-docu h1{
    font-size: 30px;
    color: #b03434;
    text-decoration: none;
    padding: 10px 20px;
    text-align: center;
}
.container-body-docu-flex h2{
    display: flex;
    justify-content: space-between;
     font-size: 24px;
    cursor: pointer;
    padding: 10px 0px;
    margin: 0px 10px;
    box-shadow: 0px 0px 11px 0 rgb(0 0 0 / 10%);
    color:#020202;
	background-color: #bfbfbf;
}

.container-body-docu-flex p{
    font-size: 20px;
    color: #020202cf;
    margin: 10px 10px 12px 10px;
    letter-spacing: 1px;

}

.container-body-docu-flex h3{
    font-size: 20px;
    color: #020202;
    margin: 10px 10px;
}


.container-body-docu-flex img{
   width: 100%;
}


.container-body-docu-flex svg{
    width: 20px;
    height: 20px;
}

@media (max-width: 599px) {
 .body-docu{
         margin-left: 0px;
 }
}

</style>
