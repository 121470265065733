import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({

routes:[
   
{

        path:'/', 
        components: {
            header: () => import ('./components/Header.vue'),
            body: () => import ('./components/Body.vue'),
            themes: () => import ('./components/Themes.vue'),
            case: () => import ('./components/Cases.vue'),
            about: () => import ('./components/About.vue'),
            tray: () => import ('./components/PartnerTray.vue'),
            faq: () => import ('./components/Faq.vue'),

            form: () => import ('./components/Form.vue'),
            footer: () => import ('./components/Footer.vue'),
        }
          
},

{
    path:'/parceiros', 
    components: {
        partner2: () => import ('./components/Partner.vue'),
        linkTray: () => import ('./components/LinkTray.vue'),
        form2: () => import ('./components/Form.vue'),
        footer2: () => import ('./components/Footer.vue')
    }
},

{
    path:'/documentacao/tray/v2', 
    components: {
        headerDocV2: () => import ('./components/HeaderDocu.vue'),
        bodyDocV2: () => import ('./components/BodyDocu.vue'),
        form3: () => import ('./components/Form.vue'),
        footer3: () => import ('./components/Footer.vue')
    }
   


},

{
    path:'/documentacao/tray/v3', 
    components: {
        headerDocV3: () => import ('./components/HeaderDocu.vue'),
        bodyDocV3: () => import ('./components/BodyDocuV3.vue'),
        formv3: () => import ('./components/Form.vue'),
        footerv3: () => import ('./components/Footer.vue')
    }
   


},
{
    path:'/documentacao/tray/v3-2', 
    components: {
        headerDocV32: () => import ('./components/HeaderDocu.vue'),
        bodyDocV32: () => import ('./components/BodyDocuV32.vue'),
        formv32: () => import ('./components/Form.vue'),
        footerv32: () => import ('./components/Footer.vue')
    }
   


},
{
    path:'/documentacao/tray/v3-4', 
    components: {
        headerDocV34: () => import ('./components/HeaderDocu.vue'),
        bodyDocV34: () => import ('./components/BodyDocuV34.vue'),
        formv34: () => import ('./components/Form.vue'),
        footerv34: () => import ('./components/Footer.vue')
    }

},

{
    path:'/documentacao/tray/v3-4-1', 
    components: {
        headerDocV341: () => import ('./components/HeaderDocu.vue'),
        bodyDocV341: () => import ('./components/BodyDocuV341.vue'),
        formv341: () => import ('./components/Form.vue'),
        footerv341: () => import ('./components/Footer.vue')
    }
},
{
    path:'/documentacao/tray/v3-4-2', 
    components: {
        headerDocV342: () => import ('./components/HeaderDocu.vue'),
        bodyDocV342: () => import ('./components/BodyDocuV342.vue'),
        formv342: () => import ('./components/Form.vue'),
        footerv342: () => import ('./components/Footer.vue')
    }
},

{
    path:'/documentacao/tray/v4', 
    components: {
        headerDocV4: () => import ('./components/HeaderDocu.vue'),
        bodyDocV4: () => import ('./components/BodyDocuV4.vue'),
        formv4: () => import ('./components/Form.vue'),
        footerv4: () => import ('./components/Footer.vue')
    }
},

{
    path:'/documentacao/tray/v4-1', 
    components: {
        headerDocV41: () => import ('./components/HeaderDocu.vue'),
        bodyDocV41: () => import ('./components/BodyDocuV41.vue'),
        formv41: () => import ('./components/Form.vue'),
        footerv41: () => import ('./components/Footer.vue')
    }
},
{
    path:'/documentacao/tray/v4-2', 
    components: {
        headerDocV42: () => import ('./components/HeaderDocu.vue'),
        bodyDocV42: () => import ('./components/BodyDocuV42.vue'),
        formv42: () => import ('./components/Form.vue'),
        footerv42: () => import ('./components/Footer.vue')
    }
},

{
    path:'/documentacao/tray/v4-3', 
    components: {
        headerDocV43: () => import ('./components/HeaderDocu.vue'),
        bodyDocV43: () => import ('./components/BodyDocuV43.vue'),
        formv43: () => import ('./components/Form.vue'),
        footerv43: () => import ('./components/Footer.vue')
    }
},

{
path:'/documentacao/tray/v4-4', 
components: {
    headerDocV44: () => import ('./components/HeaderDocu.vue'),
    bodyDocV44: () => import ('./components/BodyDocuV44.vue'),
    formv44: () => import ('./components/Form.vue'),
    footerv44: () => import ('./components/Footer.vue')
}
}
,

{
path:'/documentacao/tray/v5', 
components: {
    headerDocV5: () => import ('./components/HeaderDocu.vue'),
    bodyDocV5: () => import ('./components/BodyDocuV5.vue'),
    formv5: () => import ('./components/Form.vue'),
    footerv5: () => import ('./components/Footer.vue')
}
},

{
    path:'/documentacao/tray/v6', 
    components: {
        headerDocV6: () => import ('./components/HeaderDocu.vue'),
        bodyDocV6: () => import ('./components/BodyDocuV6.vue'),
        formv6: () => import ('./components/Form.vue'),
        footerv6: () => import ('./components/Footer.vue')
    }
    }


]
})