import Vue from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'
import './plugins/axios'
import router from './router'
import Menuleft from './components/BodyDocu.vue'
import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);
Vue.component('menuleft',Menuleft)
Vue.use(VueScrollTo)
Vue.config.productionTip = false;


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
